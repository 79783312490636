var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('span', {
    staticClass: "path",
    class: {
      light: _vm.light,
      block: _vm.block
    }
  }, [_vm._l(_vm.resolvedPath, function (item, i) {
    return [_vm._v(" " + _vm._s(i > 0 ? " / " : "") + " "), _c('router-link', {
      key: i,
      staticClass: "link",
      attrs: {
        "to": {
          name: 'CategoryInfo',
          params: _vm.getRouteParams(item),
          query: _vm.routeQuery
        },
        "target": _vm.target
      }
    }, [_vm._v(" " + _vm._s(item.c_name) + " ")])];
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };